@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="url"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="search"],
  input[type="tel"],
  input[type="checkbox"],
  input[type="radio"],
  select {
    padding: 12px 10px;
    border-width: 2px;
  }
  label {
    font-size: 18px;
    line-height: 32px;
  }
  textarea {
    border-width: 2px;
    padding: 22px 19px;
  }
}
